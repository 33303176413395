import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Link } from './link';

type Props = {
  href: string;
  keepSearch?: boolean;
  circle?: boolean;
  ghost?: boolean;
};

export function ButtonLink({
  href,
  keepSearch,
  circle,
  ghost,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Link
      href={href}
      className={classNames(
        'inline-block',
        circle ? 'p-1' : 'py-0.5 xs:py-1 px-2 xs:px-3',
        circle ? 'rounded-full' : 'rounded-md',
        ghost
          ? 'bg-base-100 hover:bg-base-300'
          : 'bg-primary-base hover:brightness-90',
        {
          'text-primary-content': !ghost,
        },
      )}
      keepSearch={keepSearch}
    >
      {children}
    </Link>
  );
}
