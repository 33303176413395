export enum Action {
  Load = 'Load',
  Click = 'Click',
  Open = 'Open',
  Close = 'Close',
  Cancel = 'Cancel',
  Delete = 'Delete',
  Join = 'Join',
  Leave = 'Leave',
  Rename = 'Rename',
  Check = 'Check',
  Update = 'Update',
  Reset = 'Reset',
  Callable = 'Callable',
  Fetch = 'Fetch',
  Sort = 'Sort',
  Categorize = 'Categorize',
  Recategorize = 'Recategorize',
}

export enum ActionModifier {
  Start = 'Start',
  End = 'End',
  Fail = 'Fail',
  NotFound = 'NotFound',
  Skip = 'Skip',
  Post = 'Post',
}
