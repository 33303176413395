import classNames from 'classnames';
import { PropsWithChildren, forwardRef } from 'react';

type ButtonProps = PropsWithChildren<{
  type?: 'button' | 'submit';
  title?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  danger?: boolean;
  secondary?: boolean;
  round?: boolean;
  circle?: boolean;
  ghost?: boolean;
  tight?: boolean;
  className?: string;
}>;

function getBackgroundColor({ danger, ghost, secondary }: ButtonProps) {
  if (ghost) {
    return '';
  }

  if (secondary) {
    return 'bg-base-100';
  }

  if (danger) {
    return 'bg-red-600';
  }

  return 'bg-primary-base';
}

function getHoverBackgroundColor({ ghost, secondary }: ButtonProps) {
  if (ghost || secondary) {
    return 'hover:bg-base-300';
  }

  return 'hover:brightness-90';
}

function getTextColor({ ghost, secondary }: ButtonProps) {
  if (!ghost && !secondary) {
    return 'text-primary-content';
  }
}

function getBorder({ ghost }: ButtonProps) {
  if (ghost) {
    return 'border-transparent';
  }

  return 'border border-base-300';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      type,
      title,
      children,
      disabled,
      tight,
      onClick,
      round = true,
      circle,
      className,
    } = props;

    return (
      <button
        ref={ref}
        type={type}
        title={title}
        onClick={onClick}
        className={classNames(
          getTextColor(props),
          { 'py-0.5 xs:py-1 px-2 xs:px-3': !circle && !tight },
          getBorder(props),
          { 'rounded-md': round && !circle },
          { 'rounded-full': circle },
          getBackgroundColor(props),
          getHoverBackgroundColor(props),
          'cursor-pointer disabled:cursor-not-allowed',
          'disabled:bg-transparent disabled:text-base-300 disabled:border-base-300',
          className,
        )}
        disabled={disabled}
      >
        {children}
      </button>
    );
  },
);
