'use client';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { HiOutlineChevronDown } from 'react-icons/hi';

export function Collapsed({
  icon,
  title,
  subTitle,
  className,
  children,
}: PropsWithChildren<{
  icon: string;
  title: string;
  subTitle: string;
  className?: string;
}>) {
  const [isExpanding, setIsExpanding] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setContentHeight(contentRef.current?.scrollHeight || 0);
  }, [children]);

  useEffect(() => {
    if (isExpanding) {
      setContentHeight(contentRef.current?.scrollHeight || 0);
      setTimeout(() => {
        setIsExpanding(false);
        setIsExpanded(true);
      }, 200);
    }
  }, [isExpanding]);

  useEffect(() => {
    if (isCollapsing) {
      setContentHeight(0);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsExpanded(false);
      }, 200);
    }
  }, [isCollapsing]);

  return (
    <div className={classNames('w-full flex flex-col', className)}>
      <button
        onClick={() =>
          isExpanded ? setIsCollapsing(true) : setIsExpanding(true)
        }
        className="block p-2"
      >
        <div className="flex items-center gap-1 xs:gap-2">
          <div className="text-xl">{icon}</div>
          <div
            className={classNames(
              'flex flex-1',
              'flex-col xs:flex-row',
              'items-center justify-between',
            )}
          >
            <span className="text-lg text-center">{title}</span>
            <div className="shrink-0 text-sm font-light text-gray-500">
              <motion.div
                key={subTitle}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
              >
                {subTitle}
              </motion.div>
            </div>
          </div>
          <div>
            <HiOutlineChevronDown
              className={classNames('transition-transform ', {
                'transform rotate-180': isExpanded || isExpanding,
                'transform rotate-0': !(isExpanded || isExpanding),
              })}
            />
          </div>
        </div>
      </button>
      <div
        className="overflow-hidden transition-height duration-200"
        style={{
          height: isExpanding || isCollapsing ? contentHeight : 'auto',
        }}
      >
        {isExpanding || isCollapsing || isExpanded ? (
          <div ref={contentRef} className="px-2 pb-2 xs:px-3 xs:pb-3">
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
}
