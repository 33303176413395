import {
  ApplicationInsights,
  ICustomProperties,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { Action, ActionModifier } from './constants';

export type Options = {
  connectionString: string;
  consoleTraces: boolean;
  initialProperties: () => ICustomProperties;
};

const sessionId = Date.now();

let options: Options;
let appInsights: ApplicationInsights;

export function initialize(initOptions: Options): void {
  if (appInsights) return;

  options = initOptions;

  const reactPlugin = new ReactPlugin();

  appInsights = new ApplicationInsights({
    config: {
      connectionString: options.connectionString,
      enableAutoRouteTracking: true,
      excludeRequestFromAutoTrackingPatterns: [
        /firestore\.googleapis\.com/,
        /\?_rsc=/,
      ],
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {},
      },
    },
  });

  appInsights.loadAppInsights();
}

function createProperties({
  action,
  actionModifier,
  properties = {},
  start,
}: {
  action: Action | string;
  actionModifier: ActionModifier;
  properties?: ICustomProperties;
  start?: number;
}): ICustomProperties {
  return {
    ...options.initialProperties(),
    ...properties,
    action,
    actionModifier,
    sessionId,
    ...(start && { duration: Date.now() - start }),
  };
}

export function trackEvent({
  name,
  action,
  actionModifier,
  properties = {},
  start,
}: {
  name: string;
  action: Action | string;
  actionModifier: ActionModifier;
  properties?: ICustomProperties;
  start?: number;
}): number {
  if (!appInsights) return Date.now();

  const event = {
    name,
    properties: createProperties({ action, actionModifier, properties, start }),
  };

  appInsights.trackEvent(event);

  if (options.consoleTraces) {
    console.log({
      name,
      action,
      actionModifier,
      ...properties,
    });
  }

  return Date.now();
}

export function trackException({
  name,
  action,
  error,
  properties = {},
  start,
}: {
  name: string;
  action: Action | string;
  error: unknown;
  properties?: ICustomProperties;
  start?: number;
}): void {
  if (!appInsights) return;

  const exception: IExceptionTelemetry = { exception: error as Error };
  const customProperties = createProperties({
    action,
    actionModifier: ActionModifier.Fail,
    properties,
    start,
  });

  trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Fail,
    properties,
    start,
  });

  appInsights.trackException(exception, {
    ...customProperties,
    name,
  });

  if (options.consoleTraces) {
    console.error(error);
  }
}

export async function trackOperation({
  name,
  action,
  properties = {},
  operation,
}: {
  name: string;
  action: Action | string;
  properties?: ICustomProperties;
  start?: number;
  operation: () => void | ICustomProperties | Promise<void | ICustomProperties>;
}): Promise<void> {
  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const result = await operation();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: {
        ...properties,
        ...(result as ICustomProperties),
      },
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
