import { ReactNode } from 'react';
import classNames from 'classnames';

export function Checkbox({
  checked,
  label,
  onChange,
}: {
  checked: boolean;
  label?: ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="checkbox"
        className={classNames(
          'w-5 h-5',
          'cursor-pointer',
          'text-primary-base',
          'bg-base-100',
          'border-base-content rounded',
          'focus:ring-primary-base focus:ring-offset-base-100',
        )}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
}
