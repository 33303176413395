'use client';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { ButtonLink } from '@reshima/navigation-ui';
import { useEffect, useState } from 'react';
import { Button } from '@reshima/pure-ui';

export type PreviousPageLinkProps = {
  href: string;
  keepSearch?: boolean;
};

export function PreviousPageLink({ href, keepSearch }: PreviousPageLinkProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return (
      <Button className="p-1" circle ghost disabled>
        <MdOutlineKeyboardArrowRight className="text-2xl" />
      </Button>
    );
  }

  return (
    <ButtonLink href={href} keepSearch={keepSearch} circle ghost>
      <MdOutlineKeyboardArrowRight className="text-2xl" />
    </ButtonLink>
  );
}
